<template>
  <LoadingSpinner v-if="loading" />
  <div v-else>
    <!-- Overlay che opacizza lo sfondo -->
    <div v-if="showModal" class="overlay"></div>

    <div class="bottom-fixed-container" v-if="!showModal">
      <button @click="openAddItemModal" class="addArticleBtn" role="button">Aggiungi articolo</button>
    </div>

    <NotificationMessage 
      v-if="notification.message" 
      :message="notification.message" 
      :type="notification.type"
    />

    <div>
      <formCreateNewItem 
        v-if="showModal"
        :isVisible="showModal"
        :addItem="addItem"
        :section="'inventory'"
        :item="selectedItem" 
        class="modal-form"
        @add-item="handleAddItemFromModal"
        @update-item="handleUpdateItemFromModal" 
        @close="showModal = false"
      />
    </div>

    <div id="itemList">
      <ItemList 
        :items="inventory"
        title="Questo è tutto ciò che hai in casa &#128515;"
        emptyMessage="Non hai nulla in casa 😞"
        @delete-item="handleDeleteItem"
        @edit-item="openEditItemModal" 
      />
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { addItem, deleteItem, updateItem } from '../main';
import formCreateNewItem from './formCreateNewItem.vue';
import LoadingSpinner from './utils/LoadingSpinner.vue';
import ItemList from './utils/ItemList.vue';
import NotificationMessage from './utils/NotificationMessage.vue';

export default {
  components: {
    formCreateNewItem,
    LoadingSpinner,
    ItemList,
    NotificationMessage,
  },
  data() {
    return {
      inventory: [],
      selectedItem: null, // Aggiunto per tenere traccia dell'articolo selezionato
      showModal: false,
      loading: true,
      notification: {
        message: '',
        type: '' // success o error
      },
    };
  },
  created() {
    this.fetchInventory();
  },
  methods: {
    addItem(newItem, section) {
      return addItem(newItem, section);
    },
    openAddItemModal() {
      this.selectedItem = null; // Resetta l'articolo selezionato per aggiungere un nuovo articolo
      this.showModal = true;
    },
    openEditItemModal(item) {
      this.selectedItem = { ...item }; // Copia articolo, quantità e unità di misura
      this.showModal = true;
    },
    handleAddItemFromModal(newItem) {
      const existingItemIndex = this.inventory.findIndex(item => item.articolo === newItem.articolo && item.unità_misura === newItem.unità_misura);

      if (existingItemIndex !== -1) {
        this.inventory[existingItemIndex].quantità += newItem.quantità;
        this.showNotification('Quantità aggiornata con successo', 'success');
      } else {
        this.inventory.push(newItem); // L'articolo contiene anche l'unità di misura
        this.showNotification('Articolo aggiunto con successo', 'success');
      }

      this.showModal = false;
    },
    
    async handleUpdateItemFromModal(updatedItem) {
      try {
        await updateItem(updatedItem.articolo, updatedItem, 'inventory');

        const existingItemIndex = this.inventory.findIndex(item => item.articolo === updatedItem.articolo && item.unità_misura === updatedItem.unità_misura);
        if (existingItemIndex !== -1) {
          this.inventory[existingItemIndex].quantità = updatedItem.quantità;
        }

        this.showModal = false;
        this.showNotification('Articolo aggiornato con successo', 'success');

        await this.fetchInventory();
      } catch (error) {
        console.error('Errore durante l\'aggiornamento dell\'articolo:', error);
        this.showNotification('Errore durante l\'aggiornamento', 'error');
      }
    },
    
    handleDeleteItem(articolo) {
      deleteItem(articolo, 'inventory')
        .then(() => {
          this.inventory = this.inventory.filter(item => item.articolo !== articolo);
          this.showNotification('Articolo eliminato con successo', 'success');
        })
        .catch((error) => {
          console.error('Errore nell\'eliminare l\'articolo:', error);
          this.showNotification('Errore durante l\'eliminazione', 'error');
        });
    },

    showNotification(message, type) {
      this.notification.message = message;
      this.notification.type = type;

      setTimeout(() => {
        this.notification.message = '';
      }, 3000);
    },
    async fetchInventory() {
      try {
        const response = await axios.get('http://127.0.0.1:5000/api/inventory');
        this.inventory = response.data.map(item => ({
          articolo: item.articolo,
          quantità: item.quantità,
          unità_misura: item.unità_misura // Assicurati che l'unità di misura sia inclusa
        }));
      } catch (error) {
        console.error('Errore nel recupero dell\'inventario:', error);
      } finally {
        this.loading = false;
      }
    },
  }
};
</script>

<style lang="scss" scoped>
@import "../scss/styles.scss";

.container {
  margin-top: 20px;
}
</style>
