<template>
  <div v-if="isVisible" class="main">
    <div class="modal-content">
      <span class="close-btn" @click="closeModal">&times;</span>
      <h3>{{ isEditing ? 'Modifica Articolo' : 'Aggiungi nuovi articoli' }}</h3>

      <button @click="askItemCount" class="addMoreBtn">+</button>

      <form @submit.prevent="handleSubmit" class="form">
        <div v-for="(item, index) in items" :key="index" class="form-group">
          <input 
            type="text" 
            v-model="item.articolo" 
            placeholder="Nome dell'articolo" 
            required 
            class="form-input"
          />
          <input 
            type="number"
            v-model="item.quantità"
            placeholder="Quantità"
            required
            class="form-input"
          />
          <select v-model="item.unità_misura" class="form-input" required>
            <option disabled value="">Seleziona unità di misura</option>
            <option value="kg">kg</option>
            <option value="pz">pz</option>
            <option value="g">g</option>
          </select>
        </div>
        <button class="addArticleBtn">{{ isEditing ? 'Aggiorna Articolo' : 'Aggiungi Articoli' }}</button>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isVisible: Boolean,
    addItem: Function,
    section: String,
    item: Object,
  },
  data() {
    return {
      items: [this.createNewItem()],
    };
  },
  computed: {
    isEditing() {
      return !!this.item;
    }
  },
  watch: {
    item: {
      handler(newVal) {
        if (newVal) {
          this.items = [{ ...newVal }];
        } else {
          this.items = [this.createNewItem()];
        }
      },
      immediate: true,
    },
    isVisible(val) {
      if (!val) {
        this.items = [this.createNewItem()];
      }
    }
  },
  methods: {
    createNewItem() {
      return { articolo: '', quantità: 0, unità_misura: '' };
    },
    askItemCount() {
      const count = parseInt(prompt('Quanti articoli vuoi aggiungere?'), 10);
      if (!isNaN(count) && count > 0) {
        this.items = Array.from({ length: count }, () => this.createNewItem());
      }
    },
    handleSubmit() {
      const addPromises = this.items.map(item => 
        this.addItem(item, this.section)
          .then(() => {
            this.$emit('add-item', { ...item });
          })
          .catch(error => console.error('Errore:', error))
      );
      
      Promise.all(addPromises).then(() => {
        this.$emit('refresh-items'); // Emetti un evento per aggiornare la lista
        this.closeModal();
      });
    },
    closeModal() {
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../scss/styles.scss";

.addMoreBtn {
  margin-bottom: 10px;
  background-color: #4CAF50;
  color: white;
  border: none;
  padding: 5px 10px;
  font-size: 18px;
  cursor: pointer;
  border-radius: 50%;
}

.main {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  width: 600px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.form {
  display: flex;
  flex-direction: column;
}

.close-btn {
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  font-size: 24px;
  color: #888;
  transition: color 0.3s;

  &:hover {
    color: #333;
  }
}

h3 {
  margin-bottom: 20px;
  text-align: center;
}

.form-group {
  margin-bottom: 15px;
  display: flex;
  justify-content: space-around;
}

.form-input {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  transition: border-color 0.3s;

  &:focus {
    border-color: #4CAF50;
    outline: none;
  }
}
</style>
